import React from 'react';

import { IRenderWidgetProps } from '../../../../assets/modules/renderWidget/model';
import { getSeasonalMerchantsWithCustomTrackingId } from '../../../../sharedModules/getSeasonalMerchantsWithCustomTrackingId';
import { getWidgetInitialiser } from '../../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../../utils/loadCSS';
import { getInitialiser } from '../../../modules/getInitialiser';
import { GetInitialiserProps, WidgetWrapper } from '../../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../../types/GetJSON';
import { MerchantLinkData } from '../../../types/MerchantLinkData';
import type { Tab } from '../../../types/Tab';
import { WidgetInitialiserOptions } from '../../../types/WidgetInitialiser';
import type { WidgetProps } from '../../../types/WidgetProps';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import ReviewContracts from '../Contracts/ReviewContracts';
import ReviewRetail from '../Retail/ReviewRetail';
import ReviewSubscriptions from '../Subscriptions/ReviewSubscriptions';

loadCSS('review');

interface SortOption {
  value: string;
  filterValue: string;
  label: string;
  desc: string;
}

interface ReviewProps {
  genericSharedComponents: WidgetProps['genericSharedComponents'];
  endpoint: WidgetProps['endpoint'];
  getDealData: WidgetProps['getDealData'];
  dealData: WidgetProps['dealData'];
  getJSON: WidgetProps['getJSON'];
  sendAnalytics: WidgetProps['sendAnalytics'];
  setDealData: WidgetProps['setDealData'];
  showToggle: WidgetProps['showToggle'];
  translate: WidgetProps['translate'];
  widgetEndpoint: WidgetProps['widgetEndpoint'];
}

class Review extends React.Component<ReviewProps> {
  getSortOptions(activeTab: Tab): SortOption[] {
    const { translate } = this.props;

    switch (activeTab?.category) {
      case 'contracts':
        return [
          {
            value: 'recommended',
            filterValue: '',
            label: translate('reviewSortRecommended'),
            desc: '',
          },
          {
            value: 'data_desc',
            filterValue: 'data',
            label: translate('reviewSortDataDesc'),
            desc: '1',
          },
          {
            value: 'data',
            filterValue: 'data',
            label: translate('reviewSortDataAsc'),
            desc: '0',
          },
          {
            value: 'monthly_cost_desc',
            filterValue: 'monthly_cost',
            label: translate('reviewSortMonthlyCostDesc'),
            desc: '1',
          },
          {
            value: 'monthly_cost',
            filterValue: 'monthly_cost',
            label: translate('reviewSortMonthlyCostAsc'),
            desc: '0',
          },
          {
            value: 'upfront_cost_desc',
            filterValue: 'upfront_cost',
            label: translate('reviewSortPriceDesc'),
            desc: '1',
          },
          {
            value: 'upfront_cost',
            filterValue: 'upfront_cost',
            label: translate('reviewSortPriceAsc'),
            desc: '0',
          },
        ];
      default:
        return [
          {
            value: 'recommended',
            filterValue: '',
            label: translate('reviewSortRecommended'),
            desc: '',
          },
          {
            value: 'upfront_cost_desc',
            filterValue: 'upfront_cost',
            label: translate('reviewSortUpfrontCostDesc'),
            desc: '1',
          },
          {
            value: 'upfront_cost',
            filterValue: 'upfront_cost',
            label: translate('reviewSortUpfrontCostAsc'),
            desc: '0',
          },
        ];
    }
  }

  render(): JSX.Element {
    const {
      genericSharedComponents: { HawkWidgetAdvanced, Main },
    } = this.props;
    return (
      <HawkWidgetAdvanced
        {...{
          ...this.props,
          getSortOptions: this.getSortOptions.bind(this),
        }}
      >
        <Consumer>
          {({ activeTab }): JSX.Element => {
            return (
              <Main
                {...{
                  ...this.props,
                  className: activeTab?.category,
                }}
              >
                {((): JSX.Element => {
                  switch (activeTab?.category) {
                    case 'subscriptions':
                      return <ReviewSubscriptions />;
                    case 'contracts':
                      return <ReviewContracts />;
                    default:
                      return <ReviewRetail />;
                  }
                })()}
              </Main>
            );
          }}
        </Consumer>
      </HawkWidgetAdvanced>
    );
  }
}

// eslint-disable-next-line
// @ts-ignore
Review.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: MerchantLinkData,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  const { data, area, defaultTab, seasonal, params } = props;
  if (seasonal === 'event' && defaultTab && data) {
    const dataEvent = data[defaultTab.value].event;
    if (dataEvent) {
      const offerType = data && data[defaultTab?.value || '']['offer_type'];
      const battle = params && params[offerType || ''] && params[offerType || ''].battle;

      dataEvent.merchants = await getSeasonalMerchantsWithCustomTrackingId(
        dataEvent,
        battle,
        articleUrl,
        site,
        area,
      );
    }
  }

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default Review;
